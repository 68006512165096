import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiOfferType } from '@app/move/enums/ui-offer-type.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-ots-contract-container-component',
    template: `
        <app-contract-container-component>
            <div class="u-flex-column">
                <div class="u-margin-bottom u-flex-row u-flex-align-items-center u-flex-justify-content-space-between">
                    <h6 class="u-color-muted ">{{ 'MOVE.ENERGY.SUGGESTION.SELECTION.TITLE' | translate }}</h6>
                    <a
                        *ngIf="(uiSandbox.upToAndIncludingTabletPortrait$ | async) && minimal"
                        (click)="showDetails()"
                        class="u-font-size-small u-color-muted u-link"
                        >{{ 'SHOW_DETAILS' | translate }}</a
                    >
                    <a
                        *ngIf="(uiSandbox.upToAndIncludingTabletPortrait$ | async) && !minimal"
                        (click)="hideDetails()"
                        class="u-font-size-small u-color-muted u-link"
                        >{{ 'HIDE_DETAILS' | translate }}</a
                    >
                </div>

                <app-suggestion-selection
                    *ngIf="(moveSandbox.payableShoppingCartHasEnergy$ | async) && showEnergy"
                    [showEdit]="true"
                    [suggestionsRoute]="energySuggestionsRoute"
                    [ngClass]="{
                        'u-margin-bottom u-bordered-bottom u-padding-bottom':
                            (moveSandbox.payableShoppingCartHasInsurances$ | async) && showInsurances
                    }"
                    [minimal]="minimal && (uiSandbox.upToAndIncludingTabletPortrait$ | async)"
                    [type]="uiOfferType.Energy"
                    [suggestion]="moveSandbox.energySuggestionSelection$ | async"
                ></app-suggestion-selection>

                <app-suggestion-selection
                    [showEdit]="true"
                    *ngIf="(moveSandbox.payableShoppingCartHasInsurances$ | async) && showInsurances"
                    [suggestionsRoute]="insurancesSuggestionsRoute"
                    [minimal]="minimal && (uiSandbox.upToAndIncludingTabletPortrait$ | async)"
                    [type]="uiOfferType.Insurances"
                    [suggestion]="moveSandbox.insurancesOfferSelection$ | async"
                ></app-suggestion-selection>
            </div>
        </app-contract-container-component>
    `,
    styleUrls: ['./ots-contract-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtsContractContainerComponent {
    @Input() public energySuggestionsRoute: string;
    @Input() public insurancesSuggestionsRoute: string;

    @Input() public showEnergy = true;
    @Input() public showInsurances = true;

    public uiOfferType = UiOfferType;
    public minimal = true;

    constructor(public moveSandbox: MoveSandbox, public uiSandbox: AppUiSandbox) {}

    public showDetails(): void {
        this.minimal = false;
    }

    public hideDetails(): void {
        this.minimal = true;
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from '@app/contract/services/contract.service';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { Legal } from '@app/move/interfaces/legal';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { PosthogService } from '@app/tracking/posthog.service';
import { PostHogEventsEnum } from '@core/enums';

@Component({
    selector: 'app-payment-details-container',
    template: `
        <app-payment-details
            (previous)="goToPrevious()"
            [showSmoovedTermsAndConditions]="showSmoovedTermsAndConditions"
            [showSuppliersTermsAndConditions]="true"
            [energyDomiciliationPaymentMandatory]="energyDomiciliationPaymentMandatory"
            stepEnd="3"
            (next)="submit()"
        ></app-payment-details>
    `,
})
export class PaymentDetailsContainer implements OnInit {
    public showSmoovedTermsAndConditions = false;
    public energyDomiciliationPaymentMandatory = false;

    constructor(
        private readonly route: ActivatedRoute,
        public readonly moveSandbox: MoveSandbox,
        private readonly router: Router,
        private readonly contractService: ContractService,
        private readonly posthogService: PosthogService
    ) {}

    public ngOnInit(): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            this.showSmoovedTermsAndConditions = !move.legal?.smoovedTermsAndConditions;
            this.energyDomiciliationPaymentMandatory = EnergyUtils.energyDomiciliationRequired(move);
        });
    }

    public goToPrevious(): void {
        this.router
            .navigate(['../contact-details'], {
                relativeTo: this.route,
            })
            .then();
    }

    public submit(): void {
        this.moveSandbox.moveOnce$.subscribe((moveState) => {
            if (!moveState._id) {
                this.moveSandbox.register({
                    mode: 'single',
                    showNotification: false,
                    callback: () => {
                        this.posthogService.sendForShoppingCart(PostHogEventsEnum.ConfirmOffer);
                        this.router.navigateByUrl(`/${Navigation.Contract}/register-success`).then();
                    },
                });
            } else {
                const legalPatch: Legal = {
                    suppliersTermsAndConditions: true,
                };

                if (!moveState?.legal?.smoovedTermsAndConditions) {
                    legalPatch.smoovedTermsAndConditions = true;
                }

                this.contractService.confirmMove(moveState._id, undefined, legalPatch);
            }
        });
    }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Legal } from '@app/move/interfaces/legal';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { zip } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ConfirmService } from './confirm.service';
import { PostHogEventsEnum } from '@core/enums';
import { PosthogService } from '@app/tracking/posthog.service';
import { ServiceType } from '@app/services/types/service-type';

@Injectable()
export class ContractService {
    constructor(
        private readonly router: Router,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly moveSandbox: MoveSandbox,
        private readonly confirmService: ConfirmService,
        private readonly posthogService: PosthogService
    ) {}

    public confirmMove(id: string, accessToken: string, legalPatch: Legal): void {
        zip(this.moveSandbox.sepaRequired$, this.confirmService.validateImpersonate())
            .pipe(
                take(1),
                tap(([sepaRequired]) => {
                    if (sepaRequired) legalPatch.sepa = true;
                })
            )
            .subscribe(() => {
                this.moveSandbox.patchProperty(
                    'legal',
                    legalPatch,
                    true,
                    this.onPatchPropertySuccess(id, accessToken),
                    null,
                    null,
                    true,
                    true
                );
            });
    }

    private onPatchPropertySuccess(id: string, accessToken: string) {
        return () => {
            this.moveSandbox.confirmOffers(id, accessToken, this.onConfirmOrdersSuccess(id, accessToken));
        };
    }

    private onConfirmOrdersSuccess(moveId: string, accessToken: string) {
        return () => {
            this.moveSandbox.confirm({ moveId, accessToken }, this.onConfirmMove);
        };
    }

    private onConfirmMove = () => {
        zip(
            this.moveSandbox.telecomOfferSelection$,
            this.moveSandbox.energyOfferSelected$,
            this.moveSandbox.telecomOfferSelected$,
            this.moveSandbox.insurancesOfferSelected$,
            this.moveSandbox.showCompleteData$
        )
            .pipe(take(1))
            .subscribe(([telecomOfferSelection, energyOfferSelected, telecomOfferSelected, insuranceOfferSelected, showCompleteData]) => {
                // we can't use sendForShoppingCart here, since the item will be removed from the shopping cart already
                if (energyOfferSelected) {
                    this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ConfirmOffer, {
                        currentService: ServiceType.EnergyNewContract,
                    });
                }
                if (telecomOfferSelection?.advice) {
                    void this.navigationSandbox.goToHome();
                    return;
                }
                if (energyOfferSelected && !telecomOfferSelected && !insuranceOfferSelected) {
                    this.router.navigateByUrl(`/${Navigation.Moves}/confirm-energy/energy-success`);
                    return;
                }

                this.router.navigateByUrl(`/${Navigation.Contract}/${showCompleteData ? 'prepared' : 'requested'}`);
            });
    };
}

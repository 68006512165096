<ng-container *ngIf="{ tabletPortraitUp: uiSandbox.tabletPortraitUp$ | async, offer: energyOffer$ | async } as vm">
    <table *ngIf="suggestion">
        <tr>
            <td
                class="u-vertical-align-middle u-padding-right-half u-text-align-right"
                *ngIf="vm.tabletPortraitUp"
                [ngClass]="{ 'u-padding-bottom': !minimal && !minimalWithoutPrice }"
            >
                <img
                    [src]="'assets/images/suppliers/' + suggestion.image"
                    *ngIf="!!suggestion.image"
                    [alt]="'Logo' + suggestion.supplier"
                    width="50px"
                    class="__image u-display-block"
                />
            </td>
            <td
                *ngIf="!minimal && !minimalWithoutPrice"
                [attr.colspan]="vm.tabletPortraitUp ? 1 : 2"
                class="u-vertical-align-middle u-w100p u-padding-bottom"
            >
                <h5>{{ suggestion.supplier }}</h5>
                <p>{{ suggestion.nameLabels | translatedLabel | async }}</p>
            </td>
            <td *ngIf="minimal" class="u-vertical-align-middle u-w100p">
                <h6>
                    <span>{{ suggestion.supplier }} </span
                    ><span class="u-font-weight-normal">{{ suggestion.nameLabels | translatedLabel | async }}</span>
                </h6>
                <div class="u-font-size-small u-color-muted" [ngSwitch]="!!suggestion.vacancy">
                    <span *ngSwitchCase="false"
                        >{{
                            (!!suggestion.totalPriceInclusivePromotions ? suggestion.totalPriceInclusivePromotions : suggestion.totalPrice)
                                | price
                        }}
                    </span>
                    <span *ngSwitchCase="false"
                        >{{ 'MOVE.ENERGY.SUGGESTION.PER_YEAR' | translate }} ({{
                            (!!suggestion.professional ? 'MOVE.ENERGY.SUGGESTION.VAT_EXCL' : 'MOVE.ENERGY.SUGGESTION.VAT_INCL') | translate
                        }}
                        )</span
                    >
                    <span *ngSwitchCase="true">{{ suggestion.totalPrice / 12 | price }} </span>
                    <span *ngSwitchCase="true"
                        >{{ 'MOVE.ENERGY.SUGGESTION.PER_MONTH' | translate }} ({{
                            (!!suggestion.professional ? 'MOVE.ENERGY.SUGGESTION.VAT_EXCL' : 'MOVE.ENERGY.SUGGESTION.VAT_INCL') | translate
                        }}
                        )</span
                    >
                </div>
            </td>

            <td *ngIf="minimalWithoutPrice" class="u-vertical-align-middle u-w100p">
                <div class="u-flex-column u-flex-align-items-start">
                    <h5>{{ suggestion.supplier }}</h5>
                    <p class="u-color-muted">
                        {{ suggestion.nameLabels | translatedLabel | async }}
                    </p>
                </div>
            </td>
        </tr>
        <ng-container *ngIf="!minimal && !minimalWithoutPrice">
            <tr>
                <td class="u-vertical-align-middle u-color-muted u-padding-right u-text-align-right __suggestion-title">
                    {{ 'MOVE.ENERGY.SUGGESTION.TARIFF' | translate }}
                </td>
                <td class="u-vertical-align-middle u-w100p">
                    {{
                        suggestion.fixedTariff
                            ? ('MOVE.ENERGY.SUGGESTIONS.FIXED_TARIFF' | translate)
                            : ('MOVE.ENERGY.SUGGESTIONS.VARIABLE_TARIFF' | translate)
                    }}
                </td>
            </tr>
            <tr>
                <td class="u-padding-bottom u-vertical-align-middle u-color-muted u-padding-right u-text-align-right __suggestion-title">
                    {{ 'MOVE.ENERGY.SUGGESTION.DURATION' | translate }}
                </td>
                <td class="u-padding-bottom u-vertical-align-middle u-w100p">
                    {{ suggestion.termInYears + ' ' + ('MOVE.ENERGY.SUGGESTION.DURATION.YEAR' | translate) }}
                </td>
            </tr>

            <ng-container *ngIf="extraInfo">
                <tr *ngIf="suggestion.type !== energyType.Electricity">
                    <td class="u-vertical-align-middle u-color-muted u-padding-right u-text-align-right __suggestion-title">
                        {{ 'MOVE.ENERGY.SUGGESTION.GAS' | translate }}
                    </td>
                    <td class="u-vertical-align-middle u-w100p">
                        {{ (suggestion.gasTotalPriceInclusivePromotions || suggestion.gasTotalPrice) / (perMonth ? 12 : 1) | price }}
                    </td>
                </tr>
                <tr *ngIf="suggestion.type !== energyType.Gas">
                    <td class="u-vertical-align-middle u-color-muted u-padding-right u-text-align-right __suggestion-title">
                        {{ 'MOVE.ENERGY.SUGGESTION.ELECTRICITY' | translate }}
                    </td>
                    <td class="u-vertical-align-middle u-w100p">
                        {{
                            (suggestion.electricityTotalPriceInclusivePromotions || suggestion.electricityTotalPrice) / (perMonth ? 12 : 1)
                                | price
                        }}
                    </td>
                </tr>
            </ng-container>

            <tr>
                <td class="u-padding-bottom u-vertical-align-top u-color-muted u-padding-right u-text-align-right">
                    {{ 'MOVE.SUGGESTION.TOTAL' | translate }}
                </td>
                <td class="u-padding-bottom u-vertical-align-top u-w100p">
                    <div class="u-flex-column">
                        <span [ngClass]="{ 'u-color-muted u-text-line-through': !!suggestion.totalPriceInclusivePromotions }">{{
                            suggestion.totalPrice / (perMonth ? 12 : 1) | price
                        }}</span>

                        <h3 class="u-color-accent" *ngIf="!!suggestion.totalPriceInclusivePromotions">
                            {{ suggestion.totalPriceInclusivePromotions / (perMonth ? 12 : 1) | price }}
                        </h3>
                        <span class="u-font-size-small u-color-muted"
                            >{{ (perMonth ? 'MOVE.ENERGY.SUGGESTION.PER_MONTH' : 'MOVE.ENERGY.SUGGESTION.PER_YEAR') | translate }} ({{
                                (!!suggestion.professional ? 'MOVE.ENERGY.SUGGESTION.VAT_EXCL' : 'MOVE.ENERGY.SUGGESTION.VAT_INCL')
                                    | translate
                            }}
                            )</span
                        >
                        <span class="u-font-size-small u-color-muted u-font-style-italic">{{
                            'MOVE.ENERGY.SUGGESTION.MONTHLY_FEE' | translate
                        }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="u-text-align-right">
                    <app-check-input [option]="perMonthOption" [custom]="false" width="auto" [formControl]="perMonthInput">
                    </app-check-input>
                </td>
            </tr>
            <tr>
                <td
                    *ngIf="showPaymentType && !!paymentType"
                    class="u-vertical-align-middle u-color-muted u-padding-right u-text-align-right"
                >
                    {{ 'PAYMENT_TYPE.LABEL' | translate }}
                </td>
                <td *ngIf="showPaymentType && !!paymentType" class="u-vertical-align-middle u-w100p">
                    {{ paymentType?.toString().toUpperCase() | translate }}
                </td>
            </tr>
            <tr>
                <td *ngIf="showEdit || suggestion.promoInfoElectricity || suggestion.promoInfoGas"></td>
                <td>
                    <div class="u-flex-row u-flex-align-items-center u-margin-top">
                        <a
                            class="u-link"
                            *ngIf="showEdit"
                            [ngClass]="{ 'u-margin-right': suggestion.promoInfoElectricity || suggestion.promoInfoGas }"
                            [routerLink]="suggestionsRoute"
                            >{{ 'EDIT' | translate }}</a
                        >
                        <app-show-promo-info
                            [suggestion]="suggestion"
                            *ngIf="suggestion.promoInfoElectricity || suggestion.promoInfoGas"
                        ></app-show-promo-info>
                    </div>
                </td>
            </tr>

            <ng-container
                *ngIf="vm.tabletPortraitUp && extraInfo"
                [ngTemplateOutlet]="assumptions"
                [ngTemplateOutletContext]="{ $implicit: vm.offer }"
            ></ng-container>
        </ng-container>
    </table>

    <ng-container *ngIf="!vm.tabletPortraitUp && extraInfo">
        <app-expansion-panel>
            <span title>{{ 'MOVE.ENERGY.SUGGESTION.ASSUMPTIONS' | translate }}</span>
            <table>
                <ng-container [ngTemplateOutlet]="assumptions" [ngTemplateOutletContext]="{ $implicit: vm.offer }"></ng-container>
            </table>
        </app-expansion-panel>
    </ng-container>
</ng-container>

<ng-template #solarPanelsTooltip>
    <p class="u-font-size-small __tooltip-content">{{ 'MOVE.ENERGY.SUGGESTION.SOLAR_PANELS.TOOLTIP' | translate }}</p>
</ng-template>
<ng-template #exclusiveNightTooltip>
    <p class="u-font-size-small __tooltip-content">{{ 'MOVE.ENERGY.SUGGESTION.EXCLUSIVE_NIGHT.TOOLTIP' | translate }}</p>
</ng-template>
<ng-template #heatElectricityTooltip>
    <p class="u-font-size-small __tooltip-content">{{ 'MOVE.ENERGY.SUGGESTION.HEAT_ELECTRICITY.TOOLTIP' | translate }}</p>
</ng-template>
<ng-template #professionalTooltip>
    <p class="u-font-size-small __tooltip-content">{{ 'MOVE.ENERGY.SUGGESTION.CONTRACT.TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #assumptions let-offer>
    <tr *ngIf="suggestion.type !== energyType.Gas">
        <td class="u-vertical-align-middle u-color-muted u-padding-right u-text-align-right __suggestion-title">
            {{ 'MOVE.ENERGY.SUGGESTION.EXCLUSIVE_NIGHT' | translate }}
        </td>
        <td class="u-vertical-align-middle u-w100p">
            {{ 'MOVE.ENERGY.SUGGESTION.SOLAR_PANELS.' + (offer.hasExclusiveNightMeter ? 'YES' : 'NO') | translate }}
            <app-icon
                appTooltip
                icon="info"
                class="u-cursor-pointer u-position-relative"
                placement="top"
                [hidePointer]="true"
                [template]="exclusiveNightTooltip"
            ></app-icon>
        </td>
    </tr>
    <tr>
        <td class="u-vertical-align-middle u-color-muted u-padding-right u-text-align-right __suggestion-title">
            {{ 'MOVE.ENERGY.SUGGESTION.SOLAR_PANELS' | translate }}
        </td>
        <td class="u-vertical-align-middle u-w100p">
            {{ 'MOVE.ENERGY.SUGGESTION.SOLAR_PANELS.' + (offer.hasSolarPanels ? 'YES' : 'NO') | translate }}
            <app-icon
                appTooltip
                icon="info"
                class="u-cursor-pointer u-position-relative"
                placement="top"
                [hidePointer]="true"
                [template]="solarPanelsTooltip"
            ></app-icon>
        </td>
    </tr>
    <tr *ngIf="suggestion.type === energyType.Electricity">
        <td class="u-vertical-align-middle u-color-muted u-padding-right u-text-align-right __suggestion-title">
            {{ 'MOVE.ENERGY.SUGGESTION.HEAT_ELECTRICITY' | translate }}
        </td>
        <td class="u-vertical-align-middle u-w100p">
            {{ 'MOVE.ENERGY.SUGGESTION.SOLAR_PANELS.' + (offer.heatElectricity ? 'YES' : 'NO') | translate }}
            <app-icon
                appTooltip
                icon="info"
                class="u-cursor-pointer u-position-relative"
                placement="top"
                [hidePointer]="true"
                [template]="heatElectricityTooltip"
            ></app-icon>
        </td>
    </tr>
    <tr>
        <td class="u-padding-bottom u-vertical-align-middle u-color-muted u-padding-right u-text-align-right __suggestion-title">
            {{ 'MOVE.ENERGY.SUGGESTION.CONTRACT' | translate }}
        </td>
        <td class="u-padding-bottom u-vertical-align-middle u-w100p">
            {{ 'MOVE.ENERGY.SUGGESTION.CONTRACT.' + (suggestion.professional ? 'PROFESSIONAL' : 'NON_PROFESSIONAL') | translate }}
            <app-icon
                appTooltip
                icon="info"
                class="u-cursor-pointer u-position-relative"
                placement="top"
                [hidePointer]="true"
                [template]="professionalTooltip"
            ></app-icon>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <div class="u-flex-column u-flex-align-items-center">
                <button
                    (click)="goToNewSimulation($event)"
                    app-button
                    type="button"
                    context="secondary"
                    appearance="stroked"
                    [routerLink]="initFlowRoute"
                    class="u-margin-bottom"
                >
                    {{ 'MOVE.ENERGY.SUGGESTION.VIEW_PARAMETERS' | translate }}
                </button>
                <a app-button appearance="link" tabindex="-1" (click)="showMoreInfo()" class="u-flex-no-shrink u-link-no-style">{{
                    'COMMON.MORE_INFO' | translate
                }}</a>
            </div>
        </td>
    </tr>
</ng-template>

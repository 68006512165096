import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-shopping-cart',
    template: `
        <div class="u-h100p u-padding-top u-padding-bottom __container u-flex-responsive">
            <div
                class="u-flex-column u-flex-1-1-0 u-flex-align-items-start"
                [ngClass]="{ 'u-margin-bottom': (uiSandbox.upToAndIncludingPhoneLandscape$ | async) }"
            >
                <p class="u-color-muted u-margin-bottom-half u-padding-right-double u-padding-left-double">
                    {{ 'DASHBOARD.SHOPPING_CART.TITLE' | translate }}
                </p>
                <div class="__suggestions-container u-w100p u-flex-row u-flex-align-items-center u-padding-left-double">
                    <app-suggestion-selection
                        *ngFor="let uiOffer of moveSandbox.shoppingCart$ | async; let last = last"
                        [ngClass]="{ 'u-margin-right': !last }"
                        class="__suggestion"
                        [type]="uiOffer.type"
                        [bordered]="true"
                        [minimalWithoutPrice]="true"
                        [suggestion]="uiOffer.suggestion"
                        [unselectable]="true"
                    ></app-suggestion-selection>
                </div>
            </div>

            <div class="u-padding-right-double u-padding-left-double u-flex-no-shrink">
                <app-button (onClick)="onClick($event)" [ngClass]="{ 'u-w100p': (uiSandbox.upToAndIncludingPhoneLandscape$ | async) }">{{
                    'DASHBOARD.SHOPPING_CART.SUBMIT' | translate
                }}</app-button>
            </div>
        </div>
    `,
    styleUrls: ['./shopping-cart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShoppingCartComponent {
    public svg = Svg;

    constructor(public moveSandbox: MoveSandbox, public uiSandbox: AppUiSandbox, private router: Router) {}

    public onClick(event): void {
        this.router.navigateByUrl(`/${Navigation.Contract}/check`).then();
    }
}

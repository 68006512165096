import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PosthogService } from '@app/tracking/posthog.service';
import { PostHogEventsEnum } from '@core/enums';

@Component({
    selector: 'app-contract-move-details-container',
    template: `
        <app-move-details stepEnd="3" [showPrevious]="!hidePreviousBtn" (next)="onSubmit()" (previous)="goToPrevious()"></app-move-details>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveDetailsContainer implements OnInit {
    public hidePreviousBtn = false;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly posthogService: PosthogService
    ) {}

    public ngOnInit() {
        this.posthogService.sendForShoppingCart(PostHogEventsEnum.ViewTransactionDetails);
        if (!this.route.snapshot.queryParams.at) return;
        //if access token is provided, no previous button should be shown
        this.hidePreviousBtn = true;
    }

    public goToPrevious(): void {
        this.router
            .navigate(['../../energy-suggestions'], {
                relativeTo: this.route,
            })
            .then();
    }

    public onSubmit(): void {
        this.posthogService.sendForShoppingCart(PostHogEventsEnum.ClickNextTransactionDetails);
        this.router
            .navigate(['../contact-details'], {
                relativeTo: this.route,
            })
            .then();
    }
}

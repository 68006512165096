import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';
import { PosthogService } from '@app/tracking/posthog.service';
import { PostHogEventsEnum } from '@core/enums';

@Component({
    selector: 'app-contact-details-container',
    template: `
        <app-contact-details
            (previous)="goToPrevious()"
            (next)="submit()"
            stepEnd="3"
            [showPassportNumber]="!!(moveSandbox.telecomOfferSelection$ | async)"
            [showNationalNumber]="!!(moveSandbox.energyOfferSelection$ | async)?.brussels"
        ></app-contact-details>
    `,
    styles: [],
})
export class ContactDetailsContainer {
    constructor(
        public readonly moveSandbox: MoveSandbox,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly posthogService: PosthogService
    ) {}

    public goToPrevious(): void {
        this.router
            .navigate(['../move-details'], {
                relativeTo: this.route,
            })
            .then();
    }

    public submit(): void {
        this.posthogService.sendForShoppingCart(PostHogEventsEnum.ClickNextContactDetails);
        this.router
            .navigate(['../payment-details'], {
                relativeTo: this.route,
            })
            .then();
    }
}
